export var ActionType;
(function (ActionType) {
    ActionType[ActionType["AddSection"] = 0] = "AddSection";
    ActionType[ActionType["ClearSections"] = 1] = "ClearSections";
    ActionType[ActionType["AutoComplete"] = 2] = "AutoComplete";
    ActionType[ActionType["ClearAutoComp"] = 3] = "ClearAutoComp";
    ActionType[ActionType["SetCurrentDir"] = 4] = "SetCurrentDir";
    ActionType[ActionType["NewCommand"] = 5] = "NewCommand";
    ActionType[ActionType["SetAutoComp"] = 6] = "SetAutoComp";
    ActionType[ActionType["SetCommand"] = 7] = "SetCommand";
    ActionType[ActionType["NextCommand"] = 8] = "NextCommand";
    ActionType[ActionType["PrevCommand"] = 9] = "PrevCommand";
    ActionType[ActionType["LoginFlow"] = 10] = "LoginFlow";
    ActionType[ActionType["RegisterFlow"] = 11] = "RegisterFlow";
    ActionType[ActionType["StartEditing"] = 12] = "StartEditing";
    ActionType[ActionType["EndEditing"] = 13] = "EndEditing";
    ActionType[ActionType["Bootstrap"] = 14] = "Bootstrap";
})(ActionType || (ActionType = {}));
