// @generated by protoc-gen-connect-es v1.5.0 with parameter "target=ts"
// @generated from file proto/command/v1/command.proto (package mainframe, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { AutoCompResponse, Command, Folder, Response, SudoCommand, SudoResponse } from "./command_pb.js";
import { Empty, MethodKind } from "@bufbuild/protobuf";
/**
 * @generated from service mainframe.shell
 */
export const shell = {
    typeName: "mainframe.shell",
    methods: {
        /**
         * @generated from rpc mainframe.shell.runCommand
         */
        runCommand: {
            name: "runCommand",
            I: Command,
            O: Response,
            kind: MethodKind.Unary,
        },
        /**
         * @generated from rpc mainframe.shell.runSudoCommand
         */
        runSudoCommand: {
            name: "runSudoCommand",
            I: SudoCommand,
            O: SudoResponse,
            kind: MethodKind.Unary,
        },
        /**
         * @generated from rpc mainframe.shell.autoComplete
         */
        autoComplete: {
            name: "autoComplete",
            I: Command,
            O: AutoCompResponse,
            kind: MethodKind.Unary,
        },
        /**
         * @generated from rpc mainframe.shell.sudoAutoComplete
         */
        sudoAutoComplete: {
            name: "sudoAutoComplete",
            I: SudoCommand,
            O: AutoCompResponse,
            kind: MethodKind.Unary,
        },
        /**
         * @generated from rpc mainframe.shell.getRoot
         */
        getRoot: {
            name: "getRoot",
            I: Empty,
            O: Folder,
            kind: MethodKind.Unary,
        },
    }
};
