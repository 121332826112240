import React, { useEffect, useRef } from 'react';
const Window = (props) => {
    const contentRef = useRef(null);
    useEffect(() => {
        let el = contentRef.current;
        if (el) {
            el.scrollTop = el.scrollHeight;
        }
    });
    return (<div ref={contentRef} className="Window_content">
            <div className="Window_contentInner">
                {props.children}
            </div>
        </div>);
};
export default Window;
