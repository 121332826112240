// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file proto/command/v1/command.proto (package mainframe, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from enum mainframe.CommandType
 */
export var CommandType;
(function (CommandType) {
    /**
     * @generated from enum value: ls = 0;
     */
    CommandType[CommandType["ls"] = 0] = "ls";
    /**
     * @generated from enum value: cat = 1;
     */
    CommandType[CommandType["cat"] = 1] = "cat";
    /**
     * @generated from enum value: cd = 2;
     */
    CommandType[CommandType["cd"] = 2] = "cd";
    /**
     * @generated from enum value: help = 3;
     */
    CommandType[CommandType["help"] = 3] = "help";
    /**
     * @generated from enum value: clear = 4;
     */
    CommandType[CommandType["clear"] = 4] = "clear";
    /**
     * @generated from enum value: landing = 5;
     */
    CommandType[CommandType["landing"] = 5] = "landing";
    /**
     * @generated from enum value: download_resume = 6;
     */
    CommandType[CommandType["download_resume"] = 6] = "download_resume";
    /**
     * @generated from enum value: login = 7;
     */
    CommandType[CommandType["login"] = 7] = "login";
    /**
     * @generated from enum value: exec = 8;
     */
    CommandType[CommandType["exec"] = 8] = "exec";
})(CommandType || (CommandType = {}));
// Retrieve enum metadata with: proto3.getEnumType(CommandType)
proto3.util.setEnumType(CommandType, "mainframe.CommandType", [
    { no: 0, name: "ls" },
    { no: 1, name: "cat" },
    { no: 2, name: "cd" },
    { no: 3, name: "help" },
    { no: 4, name: "clear" },
    { no: 5, name: "landing" },
    { no: 6, name: "download_resume" },
    { no: 7, name: "login" },
    { no: 8, name: "exec" },
]);
/**
 * @generated from enum mainframe.SudoCommandType
 */
export var SudoCommandType;
(function (SudoCommandType) {
    /**
     * @generated from enum value: logout = 0;
     */
    SudoCommandType[SudoCommandType["logout"] = 0] = "logout";
    /**
     * @generated from enum value: touch = 1;
     */
    SudoCommandType[SudoCommandType["touch"] = 1] = "touch";
    /**
     * @generated from enum value: mkdir = 2;
     */
    SudoCommandType[SudoCommandType["mkdir"] = 2] = "mkdir";
    /**
     * @generated from enum value: rm = 3;
     */
    SudoCommandType[SudoCommandType["rm"] = 3] = "rm";
    /**
     * @generated from enum value: adduser = 4;
     */
    SudoCommandType[SudoCommandType["adduser"] = 4] = "adduser";
    /**
     * @generated from enum value: edit = 5;
     */
    SudoCommandType[SudoCommandType["edit"] = 5] = "edit";
    /**
     * @generated from enum value: seed = 6;
     */
    SudoCommandType[SudoCommandType["seed"] = 6] = "seed";
    /**
     * @generated from enum value: dump = 7;
     */
    SudoCommandType[SudoCommandType["dump"] = 7] = "dump";
})(SudoCommandType || (SudoCommandType = {}));
// Retrieve enum metadata with: proto3.getEnumType(SudoCommandType)
proto3.util.setEnumType(SudoCommandType, "mainframe.SudoCommandType", [
    { no: 0, name: "logout" },
    { no: 1, name: "touch" },
    { no: 2, name: "mkdir" },
    { no: 3, name: "rm" },
    { no: 4, name: "adduser" },
    { no: 5, name: "edit" },
    { no: 6, name: "seed" },
    { no: 7, name: "dump" },
]);
/**
 * @generated from enum mainframe.ResponseType
 */
export var ResponseType;
(function (ResponseType) {
    /**
     * @generated from enum value: text = 0;
     */
    ResponseType[ResponseType["text"] = 0] = "text";
    /**
     * @generated from enum value: markdown = 1;
     */
    ResponseType[ResponseType["markdown"] = 1] = "markdown";
    /**
     * @generated from enum value: html = 2;
     */
    ResponseType[ResponseType["html"] = 2] = "html";
    /**
     * @generated from enum value: json = 3;
     */
    ResponseType[ResponseType["json"] = 3] = "json";
})(ResponseType || (ResponseType = {}));
// Retrieve enum metadata with: proto3.getEnumType(ResponseType)
proto3.util.setEnumType(ResponseType, "mainframe.ResponseType", [
    { no: 0, name: "text" },
    { no: 1, name: "markdown" },
    { no: 2, name: "html" },
    { no: 3, name: "json" },
]);
/**
 * @generated from message mainframe.AutoCompResponse
 */
export class AutoCompResponse extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated string completions = 1;
         */
        this.completions = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new AutoCompResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AutoCompResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AutoCompResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(AutoCompResponse, a, b);
    }
}
AutoCompResponse.runtime = proto3;
AutoCompResponse.typeName = "mainframe.AutoCompResponse";
AutoCompResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "completions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
]);
/**
 * @generated from message mainframe.Folder
 */
export class Folder extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string path = 5;
         */
        this.path = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new Folder().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Folder().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Folder().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Folder, a, b);
    }
}
Folder.runtime = proto3;
Folder.typeName = "mainframe.Folder";
Folder.fields = proto3.util.newFieldList(() => [
    { no: 5, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * @generated from message mainframe.SudoCommand
 */
export class SudoCommand extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: mainframe.SudoCommandType command = 1;
         */
        this.command = SudoCommandType.logout;
        /**
         * @generated from field: repeated string args = 2;
         */
        this.args = [];
        /**
         * @generated from field: string jwt = 4;
         */
        this.jwt = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new SudoCommand().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SudoCommand().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SudoCommand().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(SudoCommand, a, b);
    }
}
SudoCommand.runtime = proto3;
SudoCommand.typeName = "mainframe.SudoCommand";
SudoCommand.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "command", kind: "enum", T: proto3.getEnumType(SudoCommandType) },
    { no: 2, name: "args", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "currentDir", kind: "message", T: Folder },
    { no: 4, name: "jwt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * @generated from message mainframe.Command
 */
export class Command extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: mainframe.CommandType command = 1;
         */
        this.command = CommandType.ls;
        /**
         * @generated from field: repeated string args = 2;
         */
        this.args = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new Command().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Command().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Command().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Command, a, b);
    }
}
Command.runtime = proto3;
Command.typeName = "mainframe.Command";
Command.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "command", kind: "enum", T: proto3.getEnumType(CommandType) },
    { no: 2, name: "args", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "currentDir", kind: "message", T: Folder },
]);
/**
 * @generated from message mainframe.Response
 */
export class Response extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string resp = 3;
         */
        this.resp = "";
        /**
         * @generated from field: mainframe.ResponseType type = 4;
         */
        this.type = ResponseType.text;
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new Response().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Response().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Response().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Response, a, b);
    }
}
Response.runtime = proto3;
Response.typeName = "mainframe.Response";
Response.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "command", kind: "message", T: Command },
    { no: 2, name: "currentDir", kind: "message", T: Folder },
    { no: 3, name: "resp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "type", kind: "enum", T: proto3.getEnumType(ResponseType) },
]);
/**
 * @generated from message mainframe.SudoResponse
 */
export class SudoResponse extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string resp = 3;
         */
        this.resp = "";
        /**
         * @generated from field: mainframe.ResponseType type = 4;
         */
        this.type = ResponseType.text;
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new SudoResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SudoResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SudoResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(SudoResponse, a, b);
    }
}
SudoResponse.runtime = proto3;
SudoResponse.typeName = "mainframe.SudoResponse";
SudoResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "command", kind: "message", T: SudoCommand },
    { no: 2, name: "currentDir", kind: "message", T: Folder },
    { no: 3, name: "resp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "type", kind: "enum", T: proto3.getEnumType(ResponseType) },
]);
