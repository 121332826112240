var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from 'react';
import { useAppContext } from "./AppContext";
import ReactModal from 'react-modal';
import ReactMde from "react-mde";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm';
ReactModal.setAppElement("#root");
export const EditModal = () => {
    const [{ editing, editorFile }, { updateFile }] = useAppContext();
    const [value, setValue] = useState(editorFile.contents);
    const [selectedTab, setSelectedTab] = useState("write");
    useEffect(() => setValue(editorFile.contents), [editorFile.contents]);
    return (<ReactModal isOpen={editing} style={{
            content: {
                position: "absolute",
                left: "10%",
                right: "10%",
                top: "10%",
                bottom: "10%",
                background: "black"
            }
        }}>
            <ReactMde value={value} onChange={setValue} selectedTab={selectedTab} onTabChange={setSelectedTab} generateMarkdownPreview={async (md) => <ReactMarkdown components={{
                a(_a) {
                    var _b;
                    var { node, children } = _a, props = __rest(_a, ["node", "children"]);
                    let url = new URL((_b = props.href) !== null && _b !== void 0 ? _b : "", location.href);
                    if (url.origin !== location.origin) {
                        props.target = "_blank";
                        props.rel = "noopener noreferrer";
                    }
                    return <a {...props}>{children}</a>;
                },
            }} remarkPlugins={[remarkGfm]}>{md}</ReactMarkdown>}/><br />
            <button onClick={() => updateFile(value)}>Save</button>
        </ReactModal>);
};
