import React from 'react';
import Head from 'next/head';
import App from '../lib/App';
export default function Home() {
    return (<>
      <Head>
        <title>Bentekkie Mainframe</title>
        <meta name="description" content="Generated by create next app"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
        <meta name="theme-color" content="#000000"/>
        <link rel="manifest" href="/manifest.webmanifest"/>
        <link rel="icon" href="/favicon.ico"/>
      </Head>
      <div id='root'>
        <React.StrictMode>
          <App></App>
        </React.StrictMode>
      </div>
    </>);
}
