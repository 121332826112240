// use-client.ts
import { useMemo } from "react";
import { createPromiseClient, } from "@connectrpc/connect";
import { createConnectTransport } from "@connectrpc/connect-web";
const transport = createConnectTransport({ baseUrl: `/grpc` });
/**
* Get a promise client for the given service.
*/
export function useClient(service) {
    // We memoize the client, so that we only create one instance per service.
    return useMemo(() => createPromiseClient(service, transport), [service]);
}
